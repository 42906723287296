<template>
  <div class="container">
    <!-- 轮播图 -->
    <div class="home-banner">
      <el-carousel :interval="5000" ref="lokk" @change="check" height="600px">
        <el-carousel-item v-for="item in banner" :key="item.id">
          <h3 v-if="item.bannerType == 1">
            <video
              id="media"
              ref="videoRef"
              :src="item.videoUrl"
              width="1920"
              :fill="true"
              height="600"
              autoplay="autoplay"
              preload="auto"
              controlslist="nodownload noplaybackrate "
              disablePictureInPicture="true"
              @ended="handleended"
              @click="clickPage(item)"
              :muted="true"
              :controls="false"
            ></video>
          </h3>
          <h3 :class="item.clickEvent == 0 ? 'medium' : ''" v-else>
            <img
              @click="clickPage(item)"
              :src="item.pictureUrl"
              alt=""
              class="carousel-img"
            />
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 不同的机箱 -->
    <div class="container-content">
      <div class="content-case">
        <div class="content-case-1" v-if="!desktop == ''">
          <div class="content-case-1-text">
            <p>{{ desktop.machineName }}</p>
            <p>
              <router-link :to="`/desktop/${desktop.machineType}`">
                <span>更多</span>
                <img src="../../../assets/images/right.png" alt="" />
              </router-link>
            </p>
          </div>
          <div class="unmOrder">
            <div
              class="content-case-1-img"
              v-for="item in desktop.productInfoPOS"
              :key="item.id"
            >
              <!--<router-link :to="`/detailsGoods/${item.id}/${1}`">-->
              <div @click="detailsGoods(item,2)">
                <div class="imgs">
                  <img :src="item.pictures[0].img" />
                </div>
                <p class="title-1">{{ item.productName }}</p>
                <p class="title-2">
                  {{ item.described }}
                </p>
              </div>
              <!--</router-link>-->
            </div>
          </div>
        </div>
        <div class="content-case-2" v-if="!notebook == ''">
          <div class="content-case-1-text">
            <p>{{ notebook.machineName }}</p>
            <p>
              <router-link :to="`/desktop/${notebook.machineType}`">
                <span>更多</span>
                <img src="../../../assets/images/right.png" alt="" />
              </router-link>
            </p>
          </div>
          <div class="unmOrder">
            <div
              class="content-case-1-img"
              v-for="item in notebook.productInfoPOS"
              :key="item.id"
            >
              <!-- <router-link :to="`/detailsGoods/${item.id}/${1}`"> -->
                <div class="imgs" @click="detailsGoods(item,3)">
                  <img :src="item.pictures[0].img" alt="" />
                </div>
                <p class="title-1">{{ item.productName }}</p>
                <p class="title-2">
                  {{ item.described }}
                </p>
              <!-- </router-link> -->
            </div>
          </div>
        </div>
        <div class="content-case-3" v-if="!workstation == ''">
          <div class="content-case-1-text">
            <p>{{ workstation.machineName }}</p>
            <p>
              <router-link :to="`/desktop/${workstation.machineType}`">
                <span>更多</span>
                <img src="../../../assets/images/right.png" alt="" />
              </router-link>
            </p>
          </div>
          <div class="unmOrder">
            <div
              class="content-case-1-img"
              v-for="item in workstation.productInfoPOS"
              :key="item.id"
            >
              <!--<router-link :to="`/detailsGoods/${item.id}/${1}`">-->
              <div @click="detailsGoods(item,9)">
                <div class="imgs">
                  <img :src="item.pictures[0].img" alt="" />
                </div>
                <p class="title-1">{{ item.productName }}</p>
                <p class="title-2">
                  {{ item.described }}
                </p>
              </div>
              <!--</router-link>-->
            </div>
          </div>
        </div>
        <div class="content-case-4" v-if="!allInOne == ''">
          <div class="content-case-1-text">
            <p>{{ allInOne.machineName }}</p>
            <p>
              <router-link :to="`/desktop/${allInOne.machineType}`">
                <span>更多</span>
                <img src="../../../assets/images/right.png" alt="" />
              </router-link>
            </p>
          </div>
          <div class="unmOrder">
            <div
              class="content-case-1-img"
              v-for="item in allInOne.productInfoPOS"
              :key="item.id"
            >
              <!-- <router-link :to="`/detailsGoods/${item.id}/${1}`"> -->
                <div class="imgs" @click="detailsGoods(item,5)">
                  <img :src="item.pictures[0].img" alt="" />
                </div>
                <p class="title-1">{{ item.productName }}</p>
                <p class="title-2">
                  {{ item.described }}
                </p>
              <!-- </router-link> -->
            </div>
          </div>
        </div>
        <div class="content-case-5" v-if="!mini == ''">
          <div class="content-case-1-text">
            <p>{{ mini.machineName }}</p>
            <p>
              <router-link :to="`/desktop/${mini.machineType}`">
                <span>更多</span>
                <img src="../../../assets/images/right.png" alt="" />
              </router-link>
            </p>
          </div>
          <div class="unmOrder">
            <div
              class="content-case-1-img"
              v-for="item in mini.productInfoPOS"
              :key="item.id"
            >
              <!--<router-link :to="`/detailsGoodsNew/${item.id}/${1}`">-->
              <div @click="detailsGoods(item,6)">
                <div class="imgs">
                  <img :src="item.pictures[0].img" alt="" />
                </div>
                <p class="title-1">{{ item.productName }}</p>
                <p class="title-2">
                  {{ item.described }}
                </p>
              </div>
              <!--</router-link>-->
            </div>
          </div>
        </div>
        <div class="content-case-6" v-if="!sever == ''">
          <div class="content-case-1-text">
            <p>{{ sever.machineName }}</p>
            <p>
              <router-link :to="`/desktop/${sever.machineType}`">
                <span>更多</span>
                <img src="../../../assets/images/right.png" alt="" />
              </router-link>
            </p>
          </div>
          <div class="unmOrder">
            <div
              class="content-case-1-img"
              v-for="item in sever.productInfoPOS"
              :key="item.id"
            >
              <!--<router-link :to="`/detailsGoodsNew/${item.id}/${1}`">-->
              <div @click="detailsGoods(item,9)">
                <div class="imgs">
                  <img :src="item.pictures[0].img" alt="" />
                </div>
                <p class="title-1">{{ item.productName }}</p>
                <p class="title-2">
                  {{ item.described }}
                </p>
              </div>
              <!--</router-link>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from "vue";
import { machineBanner } from "@/api/Home/index.js";
import { EnterpriseIT } from "@/api/Home/index.js";
import { useRouter } from "vue-router";
import {baiduClue} from "@/api/shop";

const banner = ref();
const machineCode = ref(10);
const router = useRouter();
onMounted(()=>{
	// 百度埋点
	const url = window.location.href;
	if(url.search("bd_vid") != -1) {
		const bd_vid = url.match(/bd_vid=(\S*)/)[1];
		sessionStorage.setItem("bd_vid", bd_vid)
	}
})
onUnmounted(()=>{
	if(sessionStorage.getItem("bd_vid")) {
		sessionStorage.removeItem("bd_vid")
	}
})
const detailsGoods = (k,id) => {
	// 百度埋点
	if(sessionStorage.getItem("bd_vid")){
		let buriedData = [
			{
				logidUrl: window.location.href,
				newType: 27,
			}
		]
		baiduClue(buriedData)
	}
  event.stopPropagation();
  if(k.machineType == 3 || k.machineType == 5 || k.machineType == 6){
    //router.push("/detailsGoodsNew/"+ k.id + "/1");
	  router.push({
		  name: "detailsGoodsNew",
		  query: {
			  id: k.id,
			  houseNo: 1
		  }
	  });
  }else{
    //router.push("/detailsGoods/"+ k.id + "/1");
	  router.push({
		  name: "detailsGoods",
		  query: {
			  id: k.id,
			  houseNo: 1
		  }
	  });
  }
};
// 点击轮播图跳转链接
const clickPage = (val) => {
  if (val.clickEvent == 0) {
    if (val.link) {
      if (val.openWay == 1) {
        window.location.href = val.link;
      } else {
        window.open(val.link);
      }
    }
  }
};

const desktop = ref([]);
const notebook = ref([]);
const workstation = ref([]);
const allInOne = ref([]);
const mini = ref([]);
const sever = ref([]);
// 机箱类型
const getEnterpriseIT = async () => {
  try {
    let res = await EnterpriseIT();
    if (res.code === 200) {
      console.log(res.data);
      desktop.value = res.data[0];
      notebook.value = res.data[1];
      workstation.value = res.data[2];
      allInOne.value = res.data[3];
      mini.value = res.data[4];
      sever.value = res.data[5];
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  // 获取轮播图
  machineBanner(machineCode.value).then((res) => {
    const data = res.data;
    const dataPc = [];
    data.forEach((item) => {
      if (item.systemType == 0) {
        dataPc.push(item);
      }
    });
    banner.value = dataPc;
  });
  // 机箱
  getEnterpriseIT();
});
</script>

<style lang="less" scoped>
.container {
  width: 1920px;
  background-color: #ffffff;
  .medium:hover {
    cursor: pointer;
  }
  .carousel-img {
    width: 1920px;
    height: 600px;
  }
  .container-content {
    width: 1920px;
    padding-top: 60px;
    padding-bottom: 40px;
    .content-case {
      width: 1440px;
      margin: 0 auto;
      padding-bottom: 40px;
      background-color: #fff;
      .content-case-1 {
        height: 452px;
        .content-case-1-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          :nth-child(1) {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 32px;
          }
          :nth-child(2) {
            margin-right: 20px;
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
              &:hover {
                color: #ce1200;
              }
            }
            img {
              width: 6px;
              height: 12px;
              margin-left: 6px;
            }
          }
        }
        .unmOrder {
          display: flex;
          margin-left: 32px;
          .content-case-1-img {
            width: 336px;
            height: 400px;
            background: #f2f2f2;
            margin-right: 32px;
            cursor: pointer;
            .hoverShadow ();
            .imgs {
              width: 240px;
              height: 240px;
              margin-left: 42px;
              margin-bottom: 15px;
              margin-top: 38px;
              img {
                width: 240px;
                height: 240px;
                // background-color: #ce1200;
              }
            }
            .title-1 {
              width: 237px;
              font-size: 16px;
              font-weight: 400;
              color: #333;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 13px;
            }
            .title-2 {
              width: 237px;
              font-size: 13px;
              font-weight: 400;
              color: #666;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
      }
      .content-case-2 {
        height: 452px;
        margin-top: 60px;
        .content-case-1-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          :nth-child(1) {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 32px;
          }
          :nth-child(2) {
            margin-right: 20px;
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
              &:hover {
                color: #ce1200;
              }
            }
            img {
              width: 6px;
              height: 12px;
              margin-left: 6px;
            }
          }
        }
        .unmOrder {
          display: flex;
          margin-left: 32px;
          .content-case-1-img {
            width: 336px;
            height: 400px;
            background: #f2f2f2;
            margin-right: 32px;
            cursor: pointer;
            .hoverShadow ();
            .imgs {
              width: 240px;
              height: 240px;
              margin-left: 48px;
              margin-bottom: 15px;
              margin-top: 38px;
              img {
                width: 240px;
                height: 240px;
                // background-color: #ce1200;
              }
            }
            .title-1 {
              width: 237px;
              font-size: 16px;
              font-weight: 400;
              color: #333;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 13px;
            }
            .title-2 {
              width: 237px;
              font-size: 13px;
              font-weight: 400;
              color: #666;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
      }
      .content-case-3 {
        height: 452px;
        margin-top: 60px;
        .content-case-1-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          :nth-child(1) {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 32px;
          }
          :nth-child(2) {
            margin-right: 20px;
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
              &:hover {
                color: #ce1200;
              }
            }
            img {
              width: 6px;
              height: 12px;
              margin-left: 6px;
            }
          }
        }
        .unmOrder {
          display: flex;
          margin-left: 32px;
          .content-case-1-img {
            width: 336px;
            height: 400px;
            background: #f2f2f2;
            margin-right: 32px;
            cursor: pointer;
            .hoverShadow ();
            .imgs {
              width: 240px;
              height: 240px;
              margin-left: 48px;
              margin-bottom: 15px;
              margin-top: 38px;
              img {
                width: 240px;
                height: 240px;
                // background-color: #ce1200;
              }
            }
            .title-1 {
              width: 237px;
              font-size: 16px;
              font-weight: 400;
              color: #333;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 13px;
            }
            .title-2 {
              width: 237px;
              font-size: 13px;
              font-weight: 400;
              color: #666;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
      }
      .content-case-4 {
        height: 452px;
        margin-top: 60px;
        .content-case-1-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          :nth-child(1) {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 32px;
          }
          :nth-child(2) {
            margin-right: 20px;
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
              &:hover {
                color: #ce1200;
              }
            }
            img {
              width: 6px;
              height: 12px;
              margin-left: 6px;
            }
          }
        }
        .unmOrder {
          display: flex;
          margin-left: 32px;
          .content-case-1-img {
            width: 336px;
            height: 400px;
            background: #f2f2f2;
            margin-right: 32px;
            cursor: pointer;
            .hoverShadow ();
            .imgs {
              width: 240px;
              height: 240px;
              margin-left: 48px;
              margin-bottom: 15px;
              margin-top: 38px;
              img {
                width: 240px;
                height: 240px;
                // background-color: #ce1200;
              }
            }
            .title-1 {
              width: 237px;
              font-size: 16px;
              font-weight: 400;
              color: #333;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 13px;
            }
            .title-2 {
              width: 237px;
              font-size: 13px;
              font-weight: 400;
              color: #666;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
      }
      .content-case-5 {
        height: 452px;
        margin-top: 60px;
        .content-case-1-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          :nth-child(1) {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 32px;
          }
          :nth-child(2) {
            margin-right: 20px;
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
              &:hover {
                color: #ce1200;
              }
            }
            img {
              width: 6px;
              height: 12px;
              margin-left: 6px;
            }
          }
        }
        .unmOrder {
          display: flex;
          margin-left: 32px;
          .content-case-1-img {
            width: 336px;
            height: 400px;
            background: #f2f2f2;
            margin-right: 32px;
            cursor: pointer;
            .hoverShadow ();
            .imgs {
              width: 240px;
              height: 240px;
              margin-left: 48px;
              margin-bottom: 15px;
              margin-top: 38px;
              img {
                width: 240px;
                height: 240px;
                // background-color: #ce1200;
              }
            }
            .title-1 {
              width: 237px;
              font-size: 16px;
              font-weight: 400;
              color: #333;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 13px;
            }
            .title-2 {
              width: 237px;
              font-size: 13px;
              font-weight: 400;
              color: #666;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
      }
      .content-case-6 {
        height: 452px;
        margin-top: 60px;
        .content-case-1-text {
          display: flex;
          justify-content: space-between;
          margin-bottom: 30px;
          :nth-child(1) {
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 32px;
          }
          :nth-child(2) {
            margin-right: 20px;
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              cursor: pointer;
              &:hover {
                color: #ce1200;
              }
            }
            img {
              width: 6px;
              height: 12px;
              margin-left: 6px;
            }
          }
        }
        .unmOrder {
          display: flex;
          margin-left: 32px;
          .content-case-1-img {
            width: 336px;
            height: 400px;
            background: #f2f2f2;
            margin-right: 32px;
            cursor: pointer;
            .hoverShadow ();
            .imgs {
              width: 240px;
              height: 240px;
              margin-left: 48px;
              margin-bottom: 15px;
              margin-top: 38px;
              img {
                width: 240px;
                height: 240px;
                // background-color: #ce1200;
              }
            }
            .title-1 {
              width: 237px;
              font-size: 16px;
              font-weight: 400;
              color: #333;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 13px;
            }
            .title-2 {
              width: 237px;
              font-size: 13px;
              font-weight: 400;
              color: #666;
              text-align: center;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
</style>
